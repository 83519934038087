import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFaresPrimary from "../components/metro-fares-primary"

class P89ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="P89"
          description="View route information and buy your ticket for the P89 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">P89 service</h1>
              <p>Vesper Road at Lea Farm Road - Sacred Heart RC Leeds</p>
              <p>Sacred Heart RC Leeds - Vesper Road at Lea Farm Road</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="P89 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1M_jk1itypv0-_G7iXSnXBzkSTmdXtno&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Sacred Heart RC Leeds
                </p>
                <p>
                  From Old Oak Drive, Butcher Hill, Lea Farm Road, Hawkswood Crescent, Broadway, Hawkswood Crescent, Lea Farm Road, Butcher Hill, Old Oak Drive, Spen Lane, Queenswood Drive, Kirkstall Lane, Cardigan Road, Victoria Road, Chestnut Avenue, Queen's Road, Alexandra Road, Hyde Park Road, Burley Road, Kirkstall Hill, Burley Hill Drive, Argie Avenue.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="P89 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=10z9YLBLulm1GycRl5g4nmHdquZfRycM&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Sacred Heart RC Leeds
                </p>
                <p>
                  From Kirkstall Hill, Argie Avenue, Kirkstall Hill, Burley Road, Hyde Park Road, Alexandra Road, Queen's Road, Chestnut Avenue, Victoria Road, Cardigan Road, Kirkstall Lane, Queenswood Drive, Spen Lane, Old Oak Drive, Butcher Hill, Lea Farm Road, Hawkswood Crescent, Broadway, Vesper Road.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Old Oak Drive</td>
                      <td>DEP</td>
                      <td>0805</td>
                    </tr>
                    <tr>
                      <td>Queenswood Drive at Ghyll Road</td>
                      <td>DEP</td>
                      <td>0810</td>
                    </tr>
                    <tr>
                      <td>Cardigan Road, Headingley Stadium</td>
                      <td>DEP</td>
                      <td>0827</td>
                    </tr>
                    <tr>
                      <td>Alexandra Road at Kelsall Road</td>
                      <td>DEP</td>
                      <td>0830</td>
                    </tr>
                    <tr>
                      <td>Sacred Heart RC Primary School</td>
                      <td>ARR</td>
                      <td>0845</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sacred Heart RC Primary School</td>
                      <td>DEP</td>
                      <td>1530</td>
                    </tr>
                    <tr>
                      <td>Alexandra Road at Kelsall Road</td>
                      <td>DEP</td>
                      <td>1535</td>
                    </tr>
                    <tr>
                      <td>Queenswood Drive</td>
                      <td>DEP</td>
                      <td>1550</td>
                    </tr>
                    <tr>
                      <td>Vesper Road at Lea Farm Road</td>
                      <td>ARR</td>
                      <td>1600</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFaresPrimary />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default P89ServicePage
